/* You can add global styles to this file, and also import other style files */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import '@scania/theme-light/dist/styles/global-style';
@import "~codemirror/lib/codemirror";
@import "~codemirror/addon/dialog/dialog";
@import "~codemirror/addon/search/matchesonscrollbar";
@import "~codemirror/addon/merge/merge";
@import "~codemirror/theme/eclipse";
@import "~codemirror/theme/elegant";
@import "~codemirror/theme/idea";
@import "~codemirror/theme/mdn-like";
@import "~codemirror/theme/neat";
@import "~codemirror/theme/neo";
@import "~codemirror/theme/ttcn";
@import "~codemirror/theme/xq-light";

body {
    font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
}


/* start header styling */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Scania Sans Semi Condensed", Arial, Helvetica, sans-serif !important;
}

h1 {
    font-size: 6rem !important;
    line-height: 6rem !important;
    margin: 2rem 0rem 4rem;
}

h2 {
    font-size: 5.5rem !important;
    line-height: 5.5rem !important;
    margin: 2rem 0rem 4rem;
}

h3 {
    font-size: 5rem !important;
    line-height: 5rem !important;
    margin: 2rem 0rem 3.5rem;
}

h4 {
    font-size: 4.5rem !important;
    line-height: 4.5rem !important;
    margin: 2rem 0rem 3.5rem;
}

h5 {
    font-size: 4rem !important;
    line-height: 4rem !important;
    margin: 2rem 0rem 3rem;
}

h6 {
    font-size: 3.5rem !important;
    line-height: 3.5rem !important;
    margin: 2rem 0rem 3rem;
}


/* end header styling */


/* Rules for dimension of the icon. */

.mat-icon.md-14 {
    height: 14px;
    width: 14px;
}

.mat-icon.md-16 {
    height: 16px;
    width: 16px;
}

.mat-icon.md-18 {
    height: 18px;
    width: 18px;
}

.mat-icon.md-24 {
    height: 24px;
    width: 24px;
}

.mat-icon.md-36 {
    height: 36px;
    width: 36px;
}

.mat-icon.md-48 {
    height: 48px;
    width: 48px;
}


/* Rules for sizing the icon. */

.material-icons.md-14,
.material-icons-outlined.md-14,
.material-icons-round.md-14,
.material-icons-two-tone.md-14,
.material-icons-sharp.md-14 {
    font-size: 14px;
}

.material-icons.md-16,
.material-icons-outlined.md-16,
.material-icons-round.md-16,
.material-icons-two-tone.md-16,
.material-icons-sharp.md-16 {
    font-size: 16px;
}

.material-icons.md-18,
.material-icons-outlined.md-18,
.material-icons-round.md-18,
.material-icons-two-tone.md-18,
.material-icons-sharp.md-18 {
    font-size: 18px;
}

.material-icons.md-24,
.material-icons-outlined.md-24,
.material-icons-round.md-24,
.material-icons-two-tone.md-24,
.material-icons-sharp.md-24 {
    font-size: 24px;
}

.material-icons.md-36,
.material-icons-outlined.md-36,
.material-icons-round.md-36,
.material-icons-two-tone.md-36,
.material-icons-sharp.md-36 {
    font-size: 36px;
}

.material-icons.md-48,
.material-icons-outlined.md-48,
.material-icons-round.md-48,
.material-icons-two-tone.md-48,
.material-icons-sharp.md-48 {
    font-size: 48px;
}


/* Rules for using icons as black on a light background. */

.material-icons.md-dark,
.material-icons-outlined.md-dark,
.material-icons-round.md-dark,
.material-icons-two-tone.md-dark,
.material-icons-sharp.md-dark {
    color: rgba(0, 0, 0, 0.54);
}

.material-icons.md-dark.md-inactive,
.material-icons-outlined.md-dark.md-inactive,
.material-icons-round.md-dark.md-inactive,
.material-icons-two-tone.md-dark.md-inactive,
.material-icons-sharp.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
}


/* Rules for using icons as white on a dark background. */

.material-icons.md-light,
.material-icons-outlined.md-light,
.material-icons-round.md-light,
.material-icons-two-tone.md-light,
.material-icons-sharp.md-light {
    color: rgba(255, 255, 255, 1);
}

.material-icons.md-light.md-inactive,
.material-icons-outlined.md-light.md-inactive,
.material-icons-round.md-light.md-inactive,
.material-icons-two-tone.md-light.md-inactive,
.material-icons-sharp.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
}


/* Rules for using icons with 50% opacity. */

.material-icons.md-opacity,
.material-icons-outlined.md-opacity,
.material-icons-round.md-opacity,
.material-icons-two-tone.md-opacity,
.material-icons-sharp.md-opacity {
    opacity: 0.5;
}


/* end rules for using icons */

.hide {
    display: none;
}


/* start sdds-form-field styling */

.sdds-form-field {
    margin-bottom: var(--sdds-spacing-element-16);
}

.sdds-form-field label,
.sdds-form-field ul>li {
    font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
    font-size: 3.5rem;
}

.sdds-form-field ul {
    margin-top: 0rem;
    margin-bottom: 0rem;
}

.sdds-form-field input[type='file'] {
    display: none;
}

.sdds-form-field .sdds-helper {
    color: var(--sdds-textfield-helper-error);
}

.sdds-textfield-input-md {
    border: 1px solid #dee2e6;
    border-bottom: 2px solid var(--sdds-textfield-border-bottom) !important;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.sdds-textfield-input-sm {
    border: 1px solid #dee2e6;
    border-bottom: 2px solid var(--sdds-textfield-border-bottom) !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
}

.sdds-textfield-input-md:hover,
.sdds-textfield-input-sm:hover {
    border-bottom: 2px solid gray !important;
}

.sdds-textfield-input-md:focus,
.sdds-textfield-input-sm:focus {
    border-bottom: 2px solid rgba(59, 130, 246, 1) !important;
}


/* end sdds-form-field styling */


/* start sdds-btn styling */

.sdds-btn.sdds-btn-sm {
    padding: 2rem;
    height: 8rem;
}


/* end sdds-btn styling */


/* start table styling */

.table {
    width: 100%;
    text-align: left;
}

.table th,
.table td {
    padding: 2rem 3rem;
}

.table tbody tr {
    border-bottom: 1px solid #C8C9C7;
}

.table tbody tr:last-child {
    border-bottom: none;
}

.table-borderless,
.table-borderless tbody tr {
    border: none;
}

.table-borderless th {
    background-color: #f5f5f5;
}

.table-borderless thead th {
    border: none;
    color: initial;
}

.table-borderless th,
.table-borderless td {
    padding: 1rem;
}


/* end table styling */


/* start custom tabs styling */

.custom-tabs ul {
    border-bottom: 1px solid #dee2e6;
}

.custom-tabs ul>li {
    padding: 0rem !important;
    border: none !important;
    margin-bottom: -1px;
}

.custom-tabs ul>li.active,
.custom-tabs ul>li:hover,
.custom-tabs ul>li.hover {
    background-color: transparent !important;
}

.custom-tabs ul>li>a {
    padding: 3rem;
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}

.custom-tabs ul>li>a.active,
.custom-tabs ul>li>a:hover,
.custom-tabs ul>li>a.hover {
    border: 1px solid #dee2e6;
    border-bottom-color: white;
}


/* end custom tabs styling */


/* start custom sdds-badges styling */

sdds-badges {
    margin-left: 1rem;
}

sdds-badges.primary {
    --sdds-badges-bg-color: var(--sdds-grey-500);
}

sdds-badges.success {
    --sdds-badges-bg-color: var(--sdds-green-500);
}

sdds-badges.warning {
    --sdds-badges-bg-color: var(--sdds-orange-500);
}

sdds-badges.danger {
    --sdds-badges-bg-color: var(--sdds-red-500);
}


/* end custom sdds-badges styling */


/* start custom sdds-card styling */

.sdds-card {
    border: 1px solid lightgrey;
    box-shadow: none;
    border-radius: 4px;
}

.sdds-card-header {
    background-color: #f5f5f5;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}


/* end custom sdds-card styling */


/* start custom CodeMirror styling */

.CodeMirror {
    height: 50vh;
}

.CodeMirror-merge,
.CodeMirror-merge .CodeMirror {
    height: 50vh;
}


/* end custom CodeMirror styling */

.bg-color-gray {
    background-color: #f5f5f5;
}


/* loader styling */
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,0.4);  /* for semi transparent background */
    z-index: 9999;  /* to ensure loader on top of everything */
}

.loader{
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #333;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}